import React from 'react';
import "../custom.css";
import LOGO from '../images/Microsoft_logo.jpg'
import LOGO1 from '../images/MicrosoftTeams_logo.jpg'
import LOGO2 from '../images/Superheroes.jpg'
import CONT1 from '../images/Tweets2.jpg'
import CONT2 from '../images/Tweets1.jpg'
import CONF1 from '../images/MSIgnite.jpg'
import CONF2 from '../images/SharePointConf.jpg'
import { Timeline } from 'react-twitter-widgets'


const Microsoft = () => {
    
    return (
    <div className="AWM">
        <div class="container">
            <div className="contain2">
                <div class="row align-items-center my-5">
                    <div><br></br></div>
                        <center>
                            <h1 class="font-weight-light"><b>Microsoft Tech Community</b></h1>
                        </center>
                    <div class="col-lg-1">
                    </div>
                    <div class="col-lg-5">
                        <div><br></br></div>
                        <p>The <a href="https://techcommunity.microsoft.com/">Microsoft Tech Community</a> is a public platform for product teams, Microsoft MVPs, and IT Professionals to share information and build communities about products and services. It is an immensely useful resource to hundreds of thousands of daily active users and brings together people from around the world to push their technological goals to the finish line. I joined the team in 2018 and my role combined website management with strategic digital marketing, specifically for Microsoft Teams.  </p>
                    </div>
                    <div class="col-lg-1">
                    </div>
                    <div class="col-lg-4">
                        <div class="contain1">
                            <div class="contain4">
                                <div><br></br><br></br></div>
                                    <img src={LOGO} 
                                        class="img-fluid rounded mb-4 mb-lg-0"
                                    />
                            </div>
                        </div>
                        <div class="col-lg-1">
                        </div>
                    </div>



                    <div class="row align-items-center">
                        <div class="col-lg-1">
                        </div>
                        <div class="col-lg-10">
                        <div><br></br></div>
                            <center>
                        <h3 class="font-weight-light"><b>Copywriting</b></h3>
                        </center>
                        <div><br></br></div>
                            <p>Microsoft Teams has been the flagship product in Microsoft 365 since its launch in 2017 and leadership wanted to take a unified approach to strategy across blogs and social media. From the product's infancy to the giant it's become, I've played an integral part in the storytelling process acting as a ghostwriter for marketing managers and engineers.</p>
                        </div>
                        <div class="col-lg-1">
                        </div>
                    </div>
                        <div class="row align-items-center">


                        <div class="col-lg-1">
                        </div>
                                <div class="col-lg-5">
                                    
                                    <p>As manager of the editorial calendar of the <a href="https://techcommunity.microsoft.com/t5/microsoft-teams-blog/bg-p/MicrosoftTeamsBlog">Microsoft Teams Blog space</a>, I act as a copywriter and editor for many of the blogs we publish. Some of that content includes:</p>
                                    <ul>
                                <li>The monthly <a href="https://techcommunity.microsoft.com/t5/microsoft-teams-blog/bg-p/MicrosoftTeamsBlog/label-name/What's%20New">What's New in Microsoft Teams blog</a>, which brings together stakeholders from across the product team to announce and market the latest features.</li>
                               <li>Tier 1 feature announcements like the introduction of <a href="https://techcommunity.microsoft.com/t5/microsoft-teams-blog/introducing-mesh-avatars-for-microsoft-teams-in-private-preview/ba-p/3646444">Mesh avatars for Microsoft Teams</a> and larger scale marketing moments coinciding with major events like <a href="https://techcommunity.microsoft.com/t5/microsoft-teams-blog/what-s-new-in-microsoft-teams-microsoft-ignite-2020/ba-p/1665600">Microsoft Ignite</a>.</li>
                                <li>Technical documentation directed towards admins and IT Pros like enabling the  <a href="https://techcommunity.microsoft.com/t5/microsoft-teams-blog/enable-the-new-microsoft-teams-toggle-for-your-organization/ba-p/3781345">new Microsoft Teams toggle for organizations</a>.</li>
                                <li>End user aimed materials celebrating new features like <a href="https://techcommunity.microsoft.com/t5/microsoft-teams-blog/celebrate-success-using-together-emojis-in-microsoft-teams/ba-p/3797936">Together Emojis in Microsoft Teams</a> which was published alongside a larger social campaign for #NationalHighFiveDay.</li>
                                    <li>Promotional resources and support for webinars, like the <a href="https://techcommunity.microsoft.com/t5/microsoft-teams-blog/bg-p/MicrosoftTeamsBlog/label-name/Superheroes">Modern Work Superheroes</a> series or <a href="https://techcommunity.microsoft.com/t5/microsoft-teams-blog/bg-p/MicrosoftTeamsBlog/label-name/Inside%20Microsoft%20Teams">Inside Microsoft Teams</a>.</li>
                                    </ul>
                                </div>
                                <div class="col-lg-1">
                                </div>
                                <div class="col-lg-4">
                                    <div class="contain1">
                                        <div class="contain5">
                                            <div><br></br></div>
                                                <img src={LOGO1} 
                                                    class="img-fluid rounded mb-4 mb-lg-0"
                                                />
                                            <div><br></br></div>
                                            <img src={LOGO2} 
                                                    class="img-fluid rounded mb-4 mb-lg-0"
                                                />
                                        </div>
                                    </div>
                                    <div class="col-lg-1">
                                </div>
                                    </div>
                                    <div class="row align-items-center">
                        <div class="col-lg-1">
                        </div>
                        <div class="col-lg-10">
                        <div><br></br></div>
                        <div><br></br></div>
                            <center>
                        <h3 class="font-weight-light"><b>Social Media Management</b></h3>
                        </center>
                        <div><br></br></div>
                            <p>Owning an official Microsoft social channel comes with many challenges but under my editorial calendar we increased our following to over a quarter million followers. Beyond growing a larger following, I've used both the social channels and forum spaces to amplify product news and engage with users to ensure our messaging is effectively received. </p>
                        </div>
                        <div class="col-lg-1">
                        </div>
                    </div>
                        <div class="row align-items-center">


                        <div class="col-lg-1">
                        </div>
                                <div class="col-lg-5">
                                    
                                    <p>As the social media manager for Microsoft Teams, I wrote copy and created imagery for thousands of social posts. Part of my responsibilities in that role included:</p>
                                    <ul>
                                        <li>Creating and executing social media marketing campaigns across multiple platforms, in their initial inception, copywriting, design, and publication.</li>
                                        <li>Launching the official <a href="https://twitter.com/MicrosoftTeams">Microsoft Teams Twitter</a> handle and <a href="https://www.instagram.com/MicrosoftTeams">Microsoft Teams Instagram</a> profile.</li>
                                        <li>Performing reactive engagement with users and escalated technical issues to the proper channel of engineers. </li>
                                        <li>Oversaw management of other official Microsoft social profiles, including <a href="https://twitter.com/sharepoint">SharePoint</a>, <a href="https://twitter.com/MSFTExchange">Exchange</a>, <a href="https://twitter.com/MicrosoftViva">Viva</a>, <a href="https://twitter.com/yammer">Yammer</a>, and <a href="https://twitter.com/microsoftstream">Stream</a>.</li>
                                        <li>Generating in-depth analytics reports and making recommendations for future strategic initiatives.</li>
                                    </ul>
                                </div>
                                <div class="col-lg-1">
                                </div>
                                <div class="col-lg-4">
                                    <div class="contain1">
                                        <div class="contain5">
                                            <div><br></br></div>
                                            <Timeline
                                                dataSource={{
                                                    sourceType: 'profile',
                                                    screenName: 'MicrosoftTeams'
                                                }}
                                                options={{
                                                    height: '350'
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-1">
                                </div>
                                    </div>
                                    </div>
                                    <div class="col-lg-1">
                        </div>
                        
                        <div class="col-lg-5">
                        <div class="contain1">
                            
                                <div><br></br><br></br></div>
                                    
                                    <img src={CONT1} 
                                        class="img-fluid rounded mb-4 mb-lg-0"
                                    />
                                  
                            
                        </div>
                        <div class="col-lg-1">
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="contain1">
                            
                                <div><br></br><br></br></div>
                                    <img src={CONT2} 
                                        class="img-fluid rounded mb-4 mb-lg-0"
                                    />
                            
                        </div>
                        <div class="col-lg-1">
                        </div>
                    </div>
                    <div><br></br></div>
                    <div><br></br></div>

                        <div class="row align-items-center">
                            <center>
                        <h3 class="font-weight-light"><b>Project Management</b></h3>
                        </center>
                        <div><br></br></div>
                        </div>
                        <div class="col-lg-1">
                        </div>
                        <div class="col-lg-10">
                            <p>My role within the Microsoft Teams marketing group has given me the opportunity to work on incredible projects. I’ve managed vendor teams to create visual assets and manage social media campaigns, generated monthly analytics reports, hosted both in-person and virtual events, produced webinars, moderated forums, provided copywriting and editing services, staged demo content, and established brand standards for some of the most important products at Microsoft.</p>
                            <p>The Microsoft Tech Community website has undergone many overhauls and feature enhancements in my tenure and I've had the opportunity to lead some of those projects in the regular evolution of the forum and blog spaces.  </p>
                            <p>Major events like Microsoft Ignite and SharePoint Conference are big marketing moments for products like Microsoft Teams and it takes a lot to make these events a success. I always enjoy talking face-to-face with customers at the Community Booth on conference floor and its always exciting to be back stage live tweeting announcements while leadership is giving the live demo on stage.</p>    
                                </div>
                        
                        <div class="col-lg-1">
                        </div>

                        
                                 
                    <div class="col-lg-1">
                        </div>
                        
                        <div class="col-lg-5">
                        <div class="contain1">
                            
                                <div><br></br></div>
                                    <img src={CONF1} 
                                        class="img-fluid rounded mb-4 mb-lg-0"
                                    />
                            
                        </div>
                        <div class="col-lg-1">
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="contain1">
                            
                                <div><br></br></div>
                                    <img src={CONF2} 
                                        class="img-fluid rounded mb-4 mb-lg-0"
                                    />
                            
                        </div>
                        <div class="col-lg-1">
                        </div>
                    </div>
                        <div class="row align-items-center my-5">
                            <div class="col-lg-5">
                            </div>
                        </div>
                        <div class="col-lg-1">
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="container">
                <div className="contain1">
                    <div class="row align-items-center my-5">
                        <div class="col-lg-5">
                            <p><br></br></p>
                        </div>
                    </div>
                </div>
            </div>
    </div>
);
}

export default Microsoft;