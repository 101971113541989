import React from 'react';
import "../custom.css";
import ABOUT from '../images/about.jpg';
import PHONE from '../images/Phone.gif'
import ICON1 from '../images/Icon_Twitter.png'
import ICON2 from '../images/Icon_LinkedIn.png'
import ICON3 from '../images/Icon_Email.png'
import ICON4 from '../images/Icon_MSFT.png'
import { NavLink } from "react-router-dom";


const Home = () => {
    
    return (
        <div className="Web3">
        <div class="container">
            <div className="contain2">
                <div class="row align-items-center my-5">
                    <div><br></br></div>
                        <center>
                            <h1 class="font-weight-light"><b>John Steckroth</b></h1>
                            
                            </center>
                            <br></br>
                            <div class="row align-items-center my-5">
                            <div class="col-lg-1">
                            </div>
                                <div class="col-lg-5">

                                    <center>
                                <h5 class="font-weight-light"><b>Marketing Professional</b></h5>                              
                                <h5 class="font-weight-light"><b>Copywriter</b></h5>
                                <h5 class="font-weight-light"><b>Brand Strategist</b></h5>
                                </center>
                                                      
                            </div>
                            <div class="col-lg-5">
                            <center>
                            <h5 class="font-weight-light"><b>Project Manager</b></h5>
                            <h5 class="font-weight-light"><b>Amateur Web Developer</b></h5>
                            <h5 class="font-weight-light"><b>Former Journalist</b></h5>
                                </center>
                            </div>
                            <div class="col-lg-1">
                            </div>
                            </div>
                            </div>

</div>
<div className="contain2">
                <div class="row align-items-center my-5">
                    <div><br></br></div>
                        <center>
                            
                            <h3 class="font-weight-light"><b>About</b></h3>
                        </center>
                    <div class="col-lg-1">
                    </div>
                    <div class="col-lg-5">
                        <br></br>
                        <p>Marketing communications professional currently working as the Community Marketing Manager for Microsoft Teams.</p>
                        <p>For the last several years I have been providing blog and editorial strategy for Microsoft Teams while overseeing multiple official Microsoft social channels. In this time, I've written hundreds of announcement blogs, created digital marketing campaigns, amplified product news, engaged with users, hosted virtual events, produced webinars, generated analytics reports, and managed the editorial calendar. Take a look at my <NavLink to="/Microsoft">Marketing Portfolio</NavLink> with Microsoft.</p>
                       
                        <p>I previously worked as a web producer at the NBC affiliate in Detroit and had the chance to write about subjects ranging from a major city in post-bankruptcy and the automotive world within it, to leaded water and the local crime beat in one of America's most dangerous cities. Explore my <NavLink to="/WDIV">Journalism Portfolio</NavLink> at WDIV.</p>
                            
                        <p>I hold a B.A. in Journalism and English, along with certifications related to Microsoft 365, Microsoft Teams, Tech Adoption, and social media management.</p>
                    </div>
                    <div class="col-lg-1">
                    </div>
                    <div class="col-lg-4">
                        <div class="contain1">
                            <div class="contain4">
                                <div><br></br><br></br></div>
                                    <img src={ABOUT} 
                                        class="img-fluid rounded-circle mb-4 mb-lg-0"
                                    />
                            </div>
                        </div>
                        <div class="col-lg-1">
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-lg-1">
                        </div>
                        <div class="col-lg-10">
                            <p>While I don't have a formal CS background, I do have a basic understanding of javascript, python, css/html, and some solidity. I created this website in React.js and really enjoy learning about web design. </p>
                        </div>
                        <div class="col-lg-1">
                        </div>
                    </div>
                    

                    <div class="row align-items-center">
                        
                        <div class="col-lg-12">
                            
                            <br></br>
                        </div>
                        
                        
                       
                    </div>
                    
                </div>
                </div>
            
          
                <div class="container">
            <div className="contain2">
                <div class="row align-items-center my-5">
                    <div><br></br></div>
                        <center>
                           
                                    <h3 class="font-weight-light"><b>Contact</b></h3>
                                </center>
                                <div><br></br></div>
                                <center>
                                <div class="col-lg-1">
                                </div>
                                    <div class="col-lg-5">
                                        
                                        <p>Direct contact information can be found here: <a href="https://johnsteckroth.com/static/media/john_steckroth_resume.pdf" target="_blank" >John Steckroth CV</a> </p>
                                        
                                        
                                        <div className="contain1">
                                        <div class="row align-items-center my-5">
                                        <div class="col-lg-1">
                                </div>
                                
                                    <div class="col-lg-5">
                                    <a href="https://www.linkedin.com/in/johnsteckroth/" target="_blank"><img 
                                        src={ICON2}
                                        height="35px"
                                        class="rounded-circle"
                                        /></a><>&nbsp;<a href="https://www.linkedin.com/in/johnsteckroth/" target="_blank">LinkedIn</a></>
                                        <br></br>
                                        <br></br>
                                        <a href="https://twitter.com/John_Steckroth" target="_blank"><img 
                                        src={ICON1}
                                        height="35px"
                                        class="rounded-circle"
                                        /></a><>&nbsp;<a href="https://twitter.com/John_Steckroth" target="_blank">Twitter</a></>
                                        
                                        
                                    </div>

                                    <div class="col-lg-5">
                                        <a href="mailto: johnsteckroth@gmail.com" target="_blank"><img 
                                        src={ICON3}
                                        height="35px"
                                        class="rounded-circle"
                                        /></a><>&nbsp;<a href="mailto: johnsteckroth@gmail.com" target="_blank">Email</a></>
                                        <br></br>
                                        <br></br>
                                        <a href="https://techcommunity.microsoft.com/t5/user/viewprofilepage/user-id/605370#profile" target="_blank"><img 
                                        src={ICON4}
                                        height="35px"
                                        class="rounded-circle"
                                        /></a><>&nbsp;<a href="https://techcommunity.microsoft.com/t5/user/viewprofilepage/user-id/605370#profile" target="_blank">Tech Community</a></>
                                    </div>
                                    <div class="col-lg-1">
                                </div>

                                    </div></div>

                                    </div>
                                <div class="col-lg-1">
                                </div>
                                </center>
                        </div>
                    </div>

                    <div class="row align-items-center">
                        
                        <div class="col-lg-12">
                            
                            <br></br><br></br>
                        </div>
                        
                        
                       
                    </div>
               
                
                    </div>
    </div>
    </div>
);
}

export default Home;